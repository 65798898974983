import React from "react";
import loading_img from "../../assets/images/loading.gif";

export default function Loading(props) {
  return (
    <div className={props.loaderStyle ? "loading_pdf" : "loading"}>
      <img src={loading_img} alt="loader" loading="lazy" />
    </div>
  );
}

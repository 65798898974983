import axios from "axios";
import swal from "sweetalert";
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 406) {
      swal({
        title: "Error",
        text: "Dont have permission",
        icon: "error",
        button: "Ok",
      }).then(() => {});
    } else if (error.response.status === 401) {
      localStorage.clear();
    } else {
      return Promise.reject(error.response);
    }
  }
);

export default instance;

import React, { lazy, Suspense, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./assets/css/style-loan.css";
import "./assets/css/style.css";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Loading from "./components/common/Loading";
import { Provider } from "react-redux";
import store from "./store";
import Swal from "sweetalert2";
import Axios from "./shared/API";

const AppRoutes = lazy(() => import("./Approute"));

const App = () => {
  useEffect(() => {
    window.scroll(0, 0);
    Axios({
      method: "GET",
      url: `${process.env.REACT_APP_STRAPI_ROUTE_BASE_PATH}popup-banners?populate=*&sort[0]=id:desc`,
    })
      .then((res) => {
        if (!res.data || !res.data.data || res.data.data.length === 0) {
          console.log("No data received for popup banners");
          return;
        }

        const isMobile = window.innerWidth < 768;
        const data = res.data.data[0].attributes;
        let imageUrl = null;
        let altText = null;

        if (data) {
          if (isMobile) {
            if (
              data.mobile_banner &&
              data.mobile_banner.data &&
              data.mobile_banner.data.attributes
            ) {
              imageUrl = data.mobile_banner.data.attributes.url;
              altText = data.mobile_banner.data.attributes.alternativeText;
            }
          } else {
            if (
              data.banner &&
              data.banner.data &&
              data.banner.data.attributes &&
              data.banner.data.attributes.formats
            ) {
              imageUrl = data.banner.data.attributes.formats[2].url;
              altText = data.banner.data.attributes.alternativeText;
            }
          }
        }

        if (imageUrl && sessionStorage.getItem("view_pop_up") !== "hide") {
          Swal.fire({
            imageUrl: `${process.env.REACT_APP_STRAPI_UPLOAD_PATH}${imageUrl}`,
            imageAlt: altText,
            showCloseButton: true,
            showConfirmButton: false,
            allowOutsideClick: false,
            customClass: {
              container: "home-popup",
            },
          }).then(() => {
            sessionStorage.setItem("view_pop_up", "hide");
          });
        }
      })
      .catch((err) => {
        console.log("Pop UP Error", err);
      });

    document.body.style.zoom = "96%";
  }, []);

  return (
    <Provider store={store}>
      <Router>
        {/* use this during build - basename="/skfin" */}
        <Suspense fallback={<Loading />}>
          <AppRoutes />
        </Suspense>
      </Router>
    </Provider>
  );
};

export default App;

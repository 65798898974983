import {createStore, applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import commonReducer from './reducers/commonReducer';


const appReducer = combineReducers({
    common: commonReducer,
});


const store = createStore(appReducer, applyMiddleware(thunk));


export default store
import {
  GET_EMI_CALCULATOR,
  GET_USER_IP_AND_AGENT,
  GET_DROPDOWN_DATA,
  GET_ALL_BRANCHES,
  GET_SERACH_KEY,
  GET_JOBSERACH_RESULT,
  GET_NEARBY_BRANCHES,
} from "../types/commonType";

const initState = {
  emiCall: false,
  userIp: null,
  userAgent: null,
  masterData: [],
  userlatLng: { lat: null, lng: null },
  getAllBranches: [],
  searchKey: null,
  jobSearchData: [],
  nearby_branches: [],
};

const commonReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_EMI_CALCULATOR:
      return {
        ...state,
        emiCall: action.payload,
      };
    case GET_USER_IP_AND_AGENT:
      return {
        ...state,
        userIp: action.payload.userIp,
        userAgent: action.payload.userAgent,
        userlatLng: action.payload.userlatLng,
      };
    case GET_DROPDOWN_DATA:
      return {
        ...state,
        masterData: action.payload,
      };
    case GET_ALL_BRANCHES:
      return {
        ...state,
        getAllBranches: action.payload,
      };
    case GET_SERACH_KEY:
      return {
        ...state,
        searchKey: action.payload,
      };
    case GET_JOBSERACH_RESULT:
      return {
        ...state,
        jobSearchData: action.payload,
      };
    case GET_NEARBY_BRANCHES:
      return {
        ...state,
        nearby_branches: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
